@import 'app/constants.scss';

// Base styles applied throughout the gallery project.
// Please do not put styles / variables imported from other places in this project.

body {
  background-color: $BACKGROUND_BLACK;
  color: white;
  font-family: Roboto, Arial;
  touch-action: manipulation;
  user-select: none;
}

a {
  text-decoration: none;
}
