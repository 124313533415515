/** Colors */
$BLACK: #222;
$DARK_GRAY: #888;
$GRAY: #aaa;
$LIGHT_GRAY: #eee;
$VERY_LIGHT_GRAY: #f2f2f2;

// テーマカラーの紺
$LIGHT_NAVY: #243346;
$NAVY: #141C26;
$DARK_NAVY: #262d37;
$FOCUS_LIGHT_BLUE: #f5faff;

// Dark theme background black.
$BACKGROUND_BLACK: #111;
// White for black theme.
$DARK_THEME_TEXT_COLOR: #eee;
// いいね mouse over
$LIGHT_PINK: #ffaaaa;
// いいねのピンク
$PINK: #ff3256;
// マイリスト mouse over
$LIGHT_BLUE: #b8daff;
// マイリスト追加の青
$BLUE: #549ae6;
// Scene create button, tag のいろ
$VIVID_BLUE: #1889D6;
// エラーメッセージの赤
$ERROR_RED: #b00020;
// Input background when error.
$ERROR_LIGHT_RED: #ffebeb;

$SUCCESS_GREEN: #12ca4c;

// Material
$BLACK_HIGH: rgba(0, 0, 0, .87);
$BLACK_MEDIUM: rgba(0, 0, 0, .60);
$BLACK_DISABLED: rgba(0, 0, 0, .38);
$WHITE_HIGH: rgba(0, 0, 0, 1);
$WHITE_MEDIUM: rgba(0, 0, 0, .60);
$WHITE_DISABLED: rgba(0, 0, 0, .38);

// Thresholds for media query
$TABLET_MAX_WIDTH: 1024px;
$MOBILE_MAX_WIDTH: 640px;

$ZINDEX_BACK_TO_TOP_FAB: 700;
// 上部のメニュー
$ZINDEX_MENU: 900;
// Floating search box for mobiles.
$ZINDEX_MOBILE_SEARCH_BOX: 920;

$ZINDEX_DIALOG: 1100;

$HEADER_HEIGHT: calc(54px + env(safe-area-inset-top, 0px));
$FOOTER_HEIGHT: calc(54px + env(safe-area-inset-bottom, 0px));

@mixin H1 {
  font-family: Roboto;
  font-size: 96px;
  font-weight: 300;
  letter-spacing: -1.5px;
  line-height: 127px;
}
@mixin  H2 {
  font-family: Roboto;
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 79px;
}
@mixin H3 {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 63px;
}
@mixin H4 {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 45px;
}
@mixin H5 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}
@mixin H6 {
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0.25px;
  font-weight: 500;
  line-height: 26px;
}
@mixin SUBTITLE_1 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
}
@mixin SUBTITLE_2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}
@mixin BODY_1 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
}
@mixin BODY_2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}
@mixin BUTTON {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
}
@mixin CAPTION {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
}
@mixin OVERLINE {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
}
